import classNames from "classnames";
import styles from "./ErrorPage.module.css";
import Panel from "./Panel";
import typeStyles from "./typography.module.css";
import { useTranslations } from "./utils";

export default function FinishedPage() {
  const { heading, subheading } = useTranslations("finished");
  return (
    <div className={styles.root}>
      <Panel>
        <h2 className={classNames(typeStyles.subheading, styles.subheading)}>
          {heading}
        </h2>
        <p className={styles.content}>{subheading}</p>
      </Panel>
    </div>
  );
}
